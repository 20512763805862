<template>
	<div class="pb-7">
		<template v-for="(item, index) in devices">
			<v-container fluid :key="'item'+index">
				<v-row dense>
					<v-col cols="12" class="d-flex">
						<v-text-field v-bind="inputDefAttr" v-model="item.room" label="МЯСТО" class="flex-1-1" :rules="[Validators.required()]" />
						<v-btn @click="remove(index)" color="error" small class="flex-0-0 ml-2">
							<v-icon left>mdi-trash-can</v-icon>
							Премахни
						</v-btn>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<v-alert color="grey darken-1" text dense class="mb-0">
							СТАР
							<template #append>
								<v-btn icon @click="clearItemOld(index)" small><v-icon>mdi-close-circle-outline</v-icon></v-btn>
							</template>
						</v-alert>
					</v-col>
					<v-col cols="6">
						<v-alert color="grey darken-1" text dense class="mb-0">
							НОВ
							<template #append>
								<v-btn icon @click="clearItemNew(index)" small><v-icon>mdi-close-circle-outline</v-icon></v-btn>
							</template>
						</v-alert>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<base-input type="btn-toggle" v-bind="inputDefAttr" v-model="item.old_type" label="ТИП" :options="TalonPlombiDeviceTypeOptions" />
					</v-col>
					<v-col cols="6">
						<base-input type="btn-toggle" v-bind="inputDefAttr" v-model="item.new_type" label="ТИП" :options="TalonPlombiDeviceTypeOptions" />
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<v-text-field v-bind="inputDefAttr" v-model="item.old_brand" label="ПРОИЗВОДИТЕЛ"></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-text-field v-bind="inputDefAttr" v-model="item.new_brand" label="ПРОИЗВОДИТЕЛ"></v-text-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<v-text-field v-bind="inputDefAttr" v-model="item.old_num" label="ФАБРИЧЕН НОМЕР"
							:rules="item.old_type ? [Validators.required()] : []"
						></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-text-field v-bind="inputDefAttr" v-model="item.new_num" label="ФАБРИЧЕН НОМЕР"></v-text-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<base-input type="btn-toggle" v-bind="inputDefAttr" v-model="item.old_diam" label="ДИАМЕТЪР"
							:options="TalonPlombiDiamOptions" class="mr-1" active-class="primary white--text" />
						Диаметър
					</v-col>
					<v-col cols="6">
						<base-input type="btn-toggle" v-bind="inputDefAttr" v-model="item.new_diam" label="ДИАМЕТЪР"
							:options="TalonPlombiDiamOptions" class="mr-1" active-class="primary white--text" />
						Диаметър
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<v-text-field v-bind="inputDefAttr" v-model="item.old_expiry" label="ГОДЕН ДО"></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-text-field v-bind="inputDefAttr" v-model="item.new_expiry" label="ГОДЕН ДО"></v-text-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<v-text-field v-bind="inputDefAttr" v-model="item.old_range" label="ОБХВАТ"></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-text-field v-bind="inputDefAttr" v-model="item.new_range" label="ОБХВАТ"></v-text-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<v-text-field v-bind="inputDefAttr" v-model="item.old_plomba" label="ПЛОМБА"></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-text-field v-bind="inputDefAttr" v-model="item.new_plomba" label="ПЛОМБА"></v-text-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<v-text-field v-bind="inputDefAttr" v-model="item.old_value" label="ПОКАЗАНИЕ"></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-text-field v-bind="inputDefAttr" v-model="item.new_value" label="ПОКАЗАНИЕ"></v-text-field>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6" class="d-flex">
						<v-checkbox dense hide-details v-model="item.old_is_plombiran" label="Пломб. сега"></v-checkbox>
					</v-col>
					<v-col cols="6" class="d-flex">
						<v-checkbox dense hide-details v-model="item.new_is_plombiran" label="Пломб. сега"></v-checkbox>
					</v-col>
				</v-row>
			</v-container>
			<v-divider :key="'divider'+index" />
		</template>

		<div class="text-right">
			<v-btn small class="mt-4" @click="add">
				<v-icon left>mdi-plus</v-icon>
				Добави водомер
			</v-btn>
		</div>
	</div>
</template>

<script>
import {
	TalonPlombiDiamOptions,
	TalonPlombiDeviceTypeOptions,
	TalonPlombiDeviceTypeColorOptions,
} from "@/ittijs/Nomenclatures";
import BaseInput from "@/ittijs/Inputs/BaseInput.vue";
import Validators from "@/ittijs/Validators";

export default {
	components: {
		BaseInput,
	},
	props: {
		devices: Array, // syncable
	},
	computed: {
		Validators() {
			return Validators
		},
		TalonPlombiDiamOptions: () => TalonPlombiDiamOptions.map(item => ({
			value: item,
			text:  item,
		})),
		TalonPlombiDeviceTypeOptions: () => Array.from(TalonPlombiDeviceTypeOptions).map(item => ({
			value: item[0],
			text:  item[1],
			class: TalonPlombiDeviceTypeColorOptions.get(item[0]),
		})),
		inputDefAttr: () => ({
			dense: true,
			hideDetails: true,
			outlined: true,
			clearable: true,
		}),
	},
	methods: {
		add(){
			this.$emit('update:devices', [...this.devices, {
				rand: Math.floor(Math.random()*10),
				old_type         : null,
				old_brand        : null,
				old_num          : null,
				old_diam         : null,
				old_expiry       : null,
				old_range        : null,
				old_plomba       : null,
				old_value        : null,
				old_is_plombiran : null,
				new_type         : null,
				new_brand        : null,
				new_num          : null,
				new_diam         : null,
				new_expiry       : null,
				new_range        : null,
				new_plomba       : null,
				new_value        : null,
				new_is_plombiran : null,
			}]);
		},
		remove(index){
			this.$emit('update:devices', this.devices.toSpliced(index, 1));
		},
		clearItemOld(index){
			if (!this.devices[index]) return;
			this.devices[index].old_type         = null;
			this.devices[index].old_brand        = null;
			this.devices[index].old_num          = null;
			this.devices[index].old_diam         = null;
			this.devices[index].old_expiry       = null;
			this.devices[index].old_range        = null;
			this.devices[index].old_plomba       = null;
			this.devices[index].old_value        = null;
			this.devices[index].old_is_plombiran = null;
		},
		clearItemNew(index){
			if (!this.devices[index]) return;
			this.devices[index].new_type         = null;
			this.devices[index].new_brand        = null;
			this.devices[index].new_num          = null;
			this.devices[index].new_diam         = null;
			this.devices[index].new_expiry       = null;
			this.devices[index].new_range        = null;
			this.devices[index].new_plomba       = null;
			this.devices[index].new_value        = null;
			this.devices[index].new_is_plombiran = null;
		},
	},
}
</script>