<template>
	<v-form ref="form">
		<v-container fluid>

			<v-row dense>
				<v-col cols="12">
					<v-textarea outlined dense v-model="formData.findings" label="Констатация" :rules="[Validators.required()]" />
				</v-col>
			</v-row>

			<div class="">Подпис:</div>
			<signature-pad
				v-model="formData.signature_svg"
				ref="signature"
				:is-signed="isSigned"
				@update:isSigned="$emit('update:isSigned', $event)"
				width="100%" height="320px"
				required
			/>
			<div class="text-right mt-2" v-if="!hideClearSignature">
				<v-btn small color="primary" @click="clearSignature" :disabled="!isSigned">
					Изчисти
				</v-btn>
			</div>

		</v-container>
	</v-form>
</template>

<script>
import Validators from "@/ittijs/Validators";
import Model from "./Model";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import SignaturePad from "@/ittijs/components/SignaturePad.vue";

const model = ITTIModelProvider.getModel(Model);

export default {
	components: {SignaturePad},
	props: {
		jobId: {},
		hideClearSignature: Boolean,
		isSigned: Boolean,
	},
	data() {
		return {
			model,
			formData : {
				findings      : '',
				signature_svg : '',
			},
		};
	},
	computed: {
		Validators: () => Validators,
	},
	methods: {
		clearSignature() {
			this.$refs.signature.clearSignature();
		},
		async submit() {
			if (this.$refs.form.validate()) {
				return await model.createProtocolFindings(
					this.jobId,
					this.formData.findings,
					this.formData.signature_svg,
				);
			}
		},
	},
}
</script>