<template>
	<v-container fluid>
		<slot name="container-top" v-bind="{data}" />
		<v-row dense>
			<v-col cols="3">
				<date-input v-bind="inputDefAttr" label="Дата" v-model="dataInner.date" />
			</v-col>
			<v-col cols="9">
				<v-text-field v-bind="inputDefAttr" label="Клиент" v-model="dataInner.client_name" />
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<v-text-field v-bind="inputDefAttr" label="Клиентски № ВиК" v-model="dataInner.nomer_vik" :rules="[Validators.required()]" />
			</v-col>
			<v-col cols="3">
				<v-text-field v-bind="inputDefAttr" label="Клиентски № ТЕЦ" v-model="dataInner.nomer_tec" />
			</v-col>
			<v-col cols="3">
				<v-text-field v-bind="inputDefAttr" label="ЕГН / ЗДДС №" v-model="dataInner.egn_vat_no" />
			</v-col>
			<v-col cols="3">
				<v-text-field v-bind="inputDefAttr" label="БУЛСТАТ" v-model="dataInner.bulstat" />
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12">
				<v-text-field v-bind="inputDefAttr" label="Адрес" v-model="dataInner.address" />
			</v-col>
		</v-row>
		<slot name="container-before-vodomeri" v-bind="{data}" />
		<v-row dense>
			<v-col cols="12">
				<v-alert color="grey darken-1" text dense class="mb-0">
					Водомери
					<template #append>
						брой: {{ dataInner.devices.length }}
					</template>
				</v-alert>
				<vodomeri :devices.sync="dataInner.devices" />
			</v-col>
		</v-row>
		<slot name="container-bottom" v-bind="{data}" />
	</v-container>
</template>

<script>
import Vodomeri from "@/ittijs/components/TalonPlombi/Vodomeri.vue";
import DateInput from "@/ittijs/Inputs/DateInput.vue";
import {cloneDeep} from "lodash";
import Validators from "@/ittijs/Validators";

const defaultData = {
	date: null,
	client_name: null,
	nomer_vik: null,
	nomer_tec: null,
	egn_vat_no: null,
	bulstat: null,
	address: null,
	devices: [],
};

export default {
	components : {DateInput, Vodomeri},
	props: {
		data: {},
	},
	data(){
		return {
			dataInner: this.data || cloneDeep(defaultData),
		}
	},
	computed: {
		Validators() {
			return Validators
		},
		inputDefAttr: () => ({
			dense: true,
			hideDetails: true,
			outlined: true,
			clearable: true,
		}),
	},
	watch: {
		data(val){
			this.dataInner = val || cloneDeep(defaultData);
		},
		dataInner(val){
			this.$emit('update:data', val);
		},
	},
}
</script>